import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo}  alt="Mintcord Logo" /> */}
        <h1>🎤RhymeSwap🎤</h1>
        <p> Mint NFTs by adding bars to unfinished verses</p>
        <a
          // className="App-link"
          href="https://rhymeswap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="discord-button">Join the Cypher</button>
        </a>
      </header>
    </div>
  );
}

export default App;
